import React, { createContext, useState, useContext, useEffect } from 'react';
import api from '../utils/api';
import UsePersistedState from '../utils/usePersistedState';

export const AutenticacaoContext = createContext({});

export default function AutorizacaoProvider({ children }) {
    const [autorizado, setAutorizado] = useState(false);
    const [token, setToken] = UsePersistedState('token', '');
    // const [token, setToken] = useState('AAAAAAAAAAAAAAA');

    const verificarToken = async () => {
        if (token) {
            try {
                await api.post('/checar-token', null, { headers: { Authorization: token } })
                setAutorizado(true);
            } catch (error) {
                setAutorizado(false);
            }
        }
    }

    const fazerLogin = (token) => {
        setAutorizado(true);
        setToken('Bearer ' + token);
    }

    const fazerLogout = () => {
        setAutorizado(false);
        setToken('');
        localStorage.removeItem('token');
    }

    useEffect(() => {
        let isActive = true;
        if (isActive) verificarToken();
        return () => { isActive = false; };
    })

    return (
        <AutenticacaoContext.Provider value={{ autorizado, token, fazerLogin, fazerLogout }}>
            {children}
        </AutenticacaoContext.Provider>
    );
}

export function useAutenticacaoContext() {
    const context = useContext(AutenticacaoContext);
    const { autorizado, token, fazerLogin, fazerLogout } = context;
    return { autorizado, token, fazerLogin, fazerLogout };
}