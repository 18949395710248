import "./app.scss";
import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AutorizacaoProvider from "./contexts/AutorizacaoContext";

const Propriedades = React.lazy(() => import("./pages/Propriedades"));
const Login = React.lazy(() => import("./components/Login"));
const Dashboard = React.lazy(() => import('./pages/Dashboard'));
const Blog = React.lazy(() => import('./pages/Blog'));
const Linktree = React.lazy(() => import('./pages/Linktree'));

function App() {
  return (
    <div className="App">
      <AutorizacaoProvider>
        <Router>
          <Switch>
            <Suspense fallback={<div />}>
              <Route exact path="/" component={Propriedades} />
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/blog" component={Blog} />
              <Route exact path="/linktree" component={Linktree} />
              <Route path="/admin" component={Login} />
            </Suspense>
          </Switch>
        </Router>
      </AutorizacaoProvider>
    </div>
  );
}

export default App;
